// IMPORT ADDONS
@import 'addons/bootstrap/bootstrap';

/* purgecss start ignore */

// @import 'addons/font-awesome/font-awesome';
// @import 'addons/owl-carousel2/owl.carousel';
@import 'addons/layerslider/layerslider';
// @import 'addons/magnific-popup/magnific-popup';
// @import 'addons/cube-portfolio/cubeportfolio';
// @import 'addons/parallax-scroll/normalize';
// @import 'addons/parallax-scroll/main';

/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'reset';
@import 'variables';
@import 'functions';

/* GENERAL TEMPLATE */

body {
    background-color: $bg-color-body;

    h1.referencement {
        display: none !important;
    }
    .appear {
        opacity: 0;
        @include transition(opacity 0.75s ease-in);
    }
    .parallax, .parallax-slow, .parallax-fast {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }

    span.never-bold {
      font-weight: 400;
    }

    section.top {
      margin-top: -100px;
      min-height: 525px;
      div.module-header {
        img {
          height: 50vw;
          min-height: 525px;
        }
        div.content-title {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50vw;
          min-height: 500px;
          h3 {
            color: #fff;
            font-size: 65px;
            text-transform: uppercase;
            text-shadow: 0px -3px 3px rgb(25, 25, 25);
            text-align: center;
            @media screen and (max-width: 991px) {
              font-size: 45px;
            }
          }
        }
      }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
  content:"";
  display:block;
  height:$size-nav-height; /* fixed header height*/
  margin:-$size-nav-height 0 0; /* negative fixed header height */
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
@import 'menu';
// @import 'menu-centre';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

//@import 'sections/section-bursts';

/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/soumission';

/*******************************************************************************
* COMMON TO ALL PAGES
*/


/*****************************************************/
/* INDEX.PHP
/*****************************************************/


/*******************************************************************************
* X PAGE
&:hover {
    transition: all .2s linear;
}
*/

.index {
    section.section-01 {
        transition: all .2s linear;
        background-image: url('../images/a_propos_section_01_bg.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 150px 0;
        min-height: 48vw;
        @media screen and (max-width: 991px) {
          padding-bottom: 0;
        }
        div.container-fluid {
          div.row {
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 991px) {
              flex-direction: column;
            }
          }
        }
        div.left {
          @media screen and (max-width: 991px) {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 55px;
          }
        }
        .right {
          width: 38.66667%;
          background: $color-white;
          padding: 15px;
          margin-left: 2vw;
          right: -4vw;
          @media screen and (max-width: 991px) {
            width: 100%;
            margin: 0;
            margin-top: 35px;
            right: 0;
            left: 0;
          }
          .text-box {
            padding: 2.5rem;
            border: 1px solid $color-1;
            p {
              font-size: 24px;
              font-weight: 500;
              @media screen and (max-width: 991px) {
                font-size: 20px;
              }
            }
          }
        }
        .bold-red {
          color: $color-1;
          font-weight: 600;
        }
    }

    section.section-01.coordonnees {
      background-size: 0 0;
      background: #e6e7eb;
      padding: 0;
      min-height: auto;
      div.container-fluid {
        padding: 0;
        div.row {
          div.col-xs-12 {
            padding: 0;
          }
          div.col-md-6 {
            padding: 0;
            width: 50%;
            @media screen and (max-width: 1245px) {
              width: 100%;
            }
          }
          div.left-side {
            background: #e6e7eb;
            h3, h4, h5 {
              text-align: right;
            }
            div.content-left-address {
              padding-right: 85px;
              @media screen and (max-width: 1245px) {
                padding-right: 0;
              }
              h3 {
                font-size: 50px;
                @media screen and (max-width: 1245px) {
                  font-size: 35px;
                  @media screen and (max-width: 480px) {
                    font-size: 25px;
                  }
                }
              }
              h3.second-tile {
                letter-spacing: -2px;
              }
              h4 {
                font-size: 45px;
                font-weight: 400;
                @media screen and (max-width: 1245px) {
                  font-size: 30px;
                  @media screen and (max-width: 480px) {
                    font-size: 22px;
                  }
                }
                a {
                  font-weight: 500;
                }
              }
              h4.first-phone {
                margin-top: 25px;
              }
              h5 {
                font-size: 35px;
                margin-top: 30px;
                @media screen and (max-width: 1245px) {
                  font-size: 25px;
                  @media screen and (max-width: 480px) {                    
                    width: 100%;
                    font-size: 22px;
                    text-align: center;
                  }
                }
                a {
                  color: #000;
                  font-weight: 500;
                }
              }
              a:hover {
                color: #b30009;
              }
            }
          }

          div.right-side {
            background: #e6e7eb;
            h3, h4, h5 {text-align: right;}
          }
        }
      }
	}

    section.section-02 {
      background-image: url('../images/a_propos_section_02_bg.jpg');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 130px 0;
      @media screen and (max-width: 991px) {
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 25px;
        padding-bottom: 45px;
      }

      @media screen and (max-width: 480px) {
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
      }

      .col-md-offset-6 {
        margin-left: 56%;
        @media screen and (max-width: 991px) {
          margin-left: 0%;
        }
      }

      div.right-top-section-02 {
        width: 38.66667%;
        padding: 0;
        @media screen and (max-width: 991px) {
          width: 100%;
        }
      }

      h3 {
        background: $color-1;
        padding: 24px 0 24px 55px;
        color:  $color-white;
        margin: 25px 0;
        font-size: 40px;
        @media screen and (max-width: 480px) {
          font-size: 25px;
        }
      }
      h4 {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        @media screen and (max-width: 480px) {
          font-size: 20px;
        }
      }
      
      .right {
        width: 38.66667%;
        background: $color-white;
        padding: 15px;
        @media screen and (max-width: 991px) {
          width: 100%;
        }
        .text-box {
          padding: 2.5rem;
          padding-top: 1.5rem;
          border: 1px solid $color-1;
          
          ul {
            list-style-type: none;
            list-style: none;
            margin-top: 25px;
            li {
                color: #ee3a43;
                font-size: 25px;
                text-transform: none;
                font-weight: 500;
                line-height: 45px;
                @media screen and (max-width: 480px) {
                  font-size: 20px;
                }
              span {
                color: #ee3a43;
                margin-right: 15px;
              }
            }
          }
        }
      }
      .logos-box {margin-top: 30px;}
    }

    section.section-white {
      background: #FFF;
      padding: 30px 0;
      text-align: center;
      
      ul {
        display: flex;
        list-style-type: none;
        list-style: none;
        padding-left: 0;
        li {
          margin-left: 10px;
          margin-right: 10px;
          img {
            min-width: 85px;
          }img:hover {
            opacity: .5;
            transition: all .2s linear;
          }
        }
      }

      div.logo-container {
        display: flex;
        justify-content: center;
      }
      @media screen and (max-width: 767px){
        h3 {font-size: 30px;}
        div.logo-container {
          display: flex;
          justify-content: center;
          flex-flow: wrap;
        }
      }
    }

    section.section-red {
      background: $color-1;
      padding: 35px 0;
      text-align: center;
      h3 {
        color: $color-white;
        font-size: 40px;
        text-transform: uppercase;
        line-height: 60px;
      }
      @media screen and (max-width: 767px){
        h3 {font-size: 30px;}
      }
    }


    section.section-bursts {
      padding: 50px 0;
      background: $color-white;
      .row {
        @extend .flex;
        @extend .items-center;
        @extend .justify-around;
        @extend .flex-wrap;
      }
      .burst {
        img {
          opacity: 0;
          visibility: hidden;
        }
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        transition: all .2s linear;
        margin: 25px 0;
        &.left {
          background-image: url('../images/logo_kubik.png');
        }
        &.right {
          background-image: url('../images/logo_city_signature_5.png');
        }
        &:hover {
          &.left {
            background-image: url('../images/logo_kubik_hover.png');
          }
          &.right {
            background-image: url('../images/logo_city_signature_5_hover.png');
          }
        }
      }
      @media screen and (max-width: 597px) {
        .row {justify-content: center;}
      }
    }
}

// PAGE REALISATIONS
section.section-01.realisations {
  @media screen and (max-width: 1450px) {
    padding: 95px 0;
  }
  div.container-fluid.realisations {
    display: flex;
    justify-content: center;
    flex-direction: column;
    div.list-realisation-one {
      width: 100%;
      margin-bottom: 65px;
    }
    div.realisations-images {
      /*
      border: 2px solid #FFF;
      -webkit-box-shadow: 0px 0px 11px 0px rgba(50, 50, 50, 0.62);
      -moz-box-shadow: 0px 0px 11px 0px rgba(50, 50, 50, 0.62);
      box-shadow: 0px 0px 11px 0px rgba(50, 50, 50, 0.62);
      */
      div.left-image-1 {
        margin-left: 7vw;
        @media screen and (max-width: 1450px) {
          margin-left: 0%;
          margin-left: 0%;
          margin-right: 0%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img {
          border: 2px solid #FFF;
          -webkit-box-shadow: 0px 0px 11px 0px rgba(50, 50, 50, 0.62);
          -moz-box-shadow: 0px 0px 11px 0px rgba(50, 50, 50, 0.62);
          box-shadow: 0px 0px 11px 0px rgba(50, 50, 50, 0.62);
        }
      }
    }
    div.realisations-text {
      display: flex;
      justify-content: center;
      div.content-box {
        width: 75%;
        background: #FFF;
        padding: 15px;
        height: max-content;
        box-shadow: 0px 0px 11px 0px rgba(50, 50, 50, 0.2);
        @media screen and (max-width: 1450px) {
          width: 100%;
        }
        div.text-box {
          padding: 15px;
          border: 1px solid $color-1;
          h1.title-1 {
            font-size: 24px;
          text-transform: uppercase;
          color: $color-1;
          margin-top: 15px;
          margin-bottom: 5px;
          font-weight: 600;
            @media screen and (max-width: 550px) {
              font-size: 20px;
              line-height: 30px;
            }
          }
          h2.sub-title-1 {
            font-size: 24px;
            text-transform: uppercase;
            color: #aaa;
            margin-top: 15px;
            margin-bottom: 5px;
            font-weight: 600;
            border-bottom: 2px solid $color-1;
            padding-bottom: 20px;
            width: 75%;
            @media screen and (max-width: 550px) {
              font-size: 20px;
              line-height: 30px;
            }
          }
          h3.title-construction {
            font-size: 24px;
            text-transform: none;
            color: #000;
            margin-top: 20px;
            margin-bottom: -15px;
            font-weight: 600;
            padding-bottom: 20px;
            @media screen and (max-width: 550px) {
              font-size: 20px;
              line-height: 30px;
            }
          }
          h3.cout-price {
            font-size: 24px;
            text-transform: none;
            color: #aaa;
            margin-top: 15px;
            margin-bottom: 5px;
            line-height: 35px;
            @media screen and (max-width: 550px) {
              font-size: 20px;
              line-height: 30px;
            }
          }
          p {
            color: #000;
            line-height: 27px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

section.section-01.projest-futures {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 991px) {
    padding-bottom: 150px;
    @media screen and (max-width: 480px) {
      padding-bottom: 30px;
      padding-top: 30px;
    }
  }
  div.container-fluid.projets-futures {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    div.list-realisation-one {
        margin-bottom: 105px;
      div.realisations-text {        
        display: flex;
        justify-content: center;
        margin-bottom: 35px;
        @media screen and (max-width: 480px) {
          padding: 0;
        }
        div.content-box {
          width: 75%;
          background: #FFF;
          padding: 15px;
          height: max-content;
          box-shadow: 0px 0px 11px 0px rgba(50, 50, 50, 0.2);
          width: 60%;
          min-width: 850px;
          @media screen and (max-width: 1450px) {
            width: 100%;
            @media screen and (max-width: 991px) {
              min-width: auto;
              width: 100%;
            }
          }
          div.text-box {
            padding: 15px;
            border: 1px solid $color-1;
            padding-left: 8vw;
            h1.title-1 {
              font-size: 24px;
            text-transform: uppercase;
            color: $color-1;
            margin-top: 15px;
            margin-bottom: 5px;
            font-weight: 600;
              @media screen and (max-width: 550px) {
                font-size: 20px;
                line-height: 30px;
              }
            }
            h2.sub-title-1 {
              font-size: 24px;
              text-transform: uppercase;
              color: #aaa;
              margin-top: 15px;
              margin-bottom: 5px;
              font-weight: 600;
              border-bottom: 2px solid $color-1;
              padding-bottom: 20px;
              width: 540px;
              min-width: 540px;
              @media screen and (max-width: 991px) {
                min-width: auto;
                width: 100%;
              }
              @media screen and (max-width: 550px) {
                font-size: 20px;
                line-height: 30px;
              }
            }
            h3.title-construction {
              font-size: 24px;
              text-transform: none;
              color: #000;
              margin-top: 20px;
              margin-bottom: -15px;
              font-weight: 600;
              padding-bottom: 20px;
              @media screen and (max-width: 550px) {
                font-size: 20px;
                line-height: 30px;
              }
            }
            h3.cout-price {
              font-size: 24px;
              text-transform: none;
              color: #aaa;
              margin-top: 15px;
              margin-bottom: 5px;
              line-height: 35px;
              @media screen and (max-width: 550px) {
                font-size: 20px;
                line-height: 30px;
              }
            }
            p {
              color: #000;
              line-height: 27px;
              font-weight: 400;
            }
          }
        }
      }
      

      div.content-box {
        div.list-boxs {
          div.box-1.projets-futures {
            @media screen and (max-width: 480px) {
              padding: 0px;
              margin-bottom: 25px;
            }
            img {
              border: 2px solid #FFF;
              -webkit-box-shadow: 0px 0px 11px 0px rgba(50, 50, 50, 0.62);
              -moz-box-shadow: 0px 0px 11px 0px rgba(50, 50, 50, 0.62);
              box-shadow: 0px 0px 11px 0px rgba(50, 50, 50, 0.62);
            }
          }
        }        
      }
    }
  }
}

/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/
@media screen and (max-width: $size-md-max){
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}

@media screen and (max-width: $size-sm-max){
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}

@media screen and (max-width: $size-xs-max){
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {
        h2 {
            font-size: 3em;
        }
    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */
}

@media screen and (max-width: 1245px) {
  #map-canvas {
    height: 500px!important;
  }
  section.section-01.coordonnees {
    div.container-fluid {
      div.row {
        flex-direction: column;
        padding-top: 75px;
        
        div.col-md-6 {
          padding: 0;
          width: 100%;
          h3, h4, h5 {
            text-align: center;
          }          
          div.content-left-address {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding-right: 0;
            padding-bottom: 35px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  section.section-01.realisations  {
    div.container-fluid {
      padding-left: 0!important;
      padding-right: 0!important;
    }
  }  
}
