$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-28: 28px;
$font-size-30: 30px;
$font-size-32: 32px;
$font-size-34: 34px;
$font-size-36: 36px;
$font-size-38: 38px;
$font-size-40: 40px;
$font-size-42: 42px;
$font-size-44: 44px;
$font-size-46: 46px;
$font-size-48: 48px;
$font-size-50: 50px;
$font-size-60: 60px;
$font-size-64: 64px;
$font-size-70: 70px;
$font-size-80: 80px;
$font-size-90: 90px;
$font-size-100: 100px;
