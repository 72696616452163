html {
    font-size: $font-size-base-lg; // used for rem units
}
@media screen and (max-width: $size-md-max) {
    html {
        font-size: $font-size-14;
    }
}
@media screen and (max-width: $size-sm-max) {
    html {
        font-size: $font-size-12;
    }
}
@media screen and (max-width: $size-xs-max) {
    html {
        font-size: $font-size-12;
    }
}
body {
    @extend .text-color;
    @extend .font-1;
    line-height: 1;
    a {
        @extend .text-color-primary;
        @extend .font-1;
        text-decoration: none !important;
        &:hover, &:focus {
            text-decoration: none !important;
             @extend .text-color-contrast;
        }
    }
    a[href^=tel], a[href^=mailto] {
        &:hover {

        }
    }
}

h1, h2, h3, h4, h5, h6 {
    @extend .font-1;
}

p {
    @extend .font-main;
    line-height: 2;
    font-weight: 400;
}
ul {padding-left: 20px;}
li {
  @extend .font-1-bold;
  line-height: 3;
  text-transform: uppercase;
}

.text p, .text div {
    @extend .text-color;
    line-height: 1.4;
}
.text p, .text div {
    @extend .font-1;
}
.text strong {
    color: $color-primary;
    font-weight: 900;
}
.text em {
    color: $color-2;
    font-style: normal;
    font-weight: 600;
}
