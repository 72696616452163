section.slides {
    margin-top: -100px;
}
.slideshow-wrap {
    position: relative;
    #layerslider { display: block; }
    #layerslider-mobile { display: none; }
    @media screen and (max-width: $size-slider-breakpoint) {
        #layerslider { display: none; }
        #layerslider-mobile { display: block; }
    }

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .caption {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      width: 100%;
      text-align: center;
      img {margin: auto;}
    }
    .subtitle {
        color: $color-white;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        font-size: 1.5vw;
        text-align: center;
        padding-bottom:0.5em;
    }
    .title {
        @extend .font-1;
        font-weight: 500;
        text-transform: uppercase;
        color: $color-white;
        font-size: 50px;
        text-align: center;
        text-shadow: 0 0 10px $color-black;
    }
    #title-4 {
        @extend .font-1;
        font-weight: 500!important;
        text-transform: uppercase;
        color: $color-white;
        margin-top: 31px!important;
        font-size: 32px!important;
        text-shadow: 0 0 10px $color-black!important;
        font-weight: bold!important;
        top: 65% !important;
        left: 0;
        text-align: center;
    }

    @media screen and (max-width: 1500px) {
        #title-4 {
            top: 70% !important;
        }
    }

    @media screen and (max-width: 1350px) {
        #title-4 {
            top: 75% !important;
            font-size: 32px!important;
        }
    }
    
    @media screen and (max-width: 991px) and (min-width: 479px) {
        #title-4 {
            display: none;
        }
    }

    #image_layer {
        top: 700px !important;
        position: absolute!important;
    }
    .ls-l a {
        width: auto;
    }
    @media screen and (max-width: $size-xs-max) {
      .title {
          font-size: 26px;
      }
      #title-4 {
        font-size: 20px!important;
      }
      .caption  {
        img {
          max-width: 300px;
        }
      }
        .ls-l a {
            font-size: 11px;
            border-width: 1px;
            padding: 5px;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        .title {
            font-size: 26px;
        }
        #title-4 {
            font-size: 20px;
          }
        .caption  {
          img {
            max-width: 100%;
          }
        }
        .subtitle {
            font-size: 16px;
        }
    }
}

